import { render, staticRenderFns } from "./makeSuccss.vue?vue&type=template&id=4f8b82fa&scoped=true&"
import script from "./makeSuccss.vue?vue&type=script&lang=ts&"
export * from "./makeSuccss.vue?vue&type=script&lang=ts&"
import style0 from "./makeSuccss.vue?vue&type=style&index=0&id=4f8b82fa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8b82fa",
  null
  
)

export default component.exports