












import { Component, Vue, Prop, Watch } from "vue-property-decorator";

/**
 * 预约成功
 * @class
 * @version 1.0.0
 */
@Component
export default class MakeSuccess extends Vue
{
    /**
     * 订单id
     * @private
     */
    private orderId = null;
    
    /**
     * 需要跳转页面的name
     * @private 
     */
    private actionName = "";
    /**
     * 跳转单据详情
     * @private
     * @returns void
     */
    private onLookMake(): void
    {
        this.$router.push({name: this.actionName, query:{orderId: this.orderId}});
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.orderId = parseInt((this.$route.query as any).orderId);
        this.actionName = (this.$route.query as any).actionName;
    }

}
