

















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import EnumUtils from "src/utils/enum-utils";
import { RELATION, ID_TYPE, MARRIAGE_STATUS } from "src/enums";
import { Toast, Dialog } from "vant";
import patterns from "src/utils/patterns";
import { FamilyService, CellService, UserService } from "src/services";
import area from "src/components/area/area";
import dayjs from "dayjs";
declare let WeixinJSBridge: any 
declare let document: any

/**
 * 常用联系人表单
 * @class
 * @version 1.0.0
 */
@Component({
    components:{
        "u-area":area
    }
})
export default class Contacts extends Vue
{
    // 当前日期后3天
    private dateThree: any = new Date(dayjs().add(3, 'day').valueOf());

    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected selectUser: any = {};

    /**
     * 详情
     * @private
     * @returns any
     */
    private detailInfo: any = {};

    /**
     * 日期
     * @private
     * @returns any
     */
    private selectDate: any = null;

    /**
     * 是否显示日历控件
     * @private
     * @returns any
     */
    private showDate: boolean = false;

    /**
     * 显示地区
     * @private
     * @returns any
     */
    private showArea: boolean = false;

    /**
     * 
     */
    private formData: any =
    {
        memberId: null, // 客户编号，外键
        reserveTime: "", // 预约日期
        memberServiceItemId: null,// 权益包服务编号
        cellStorageItemId: null, // 细胞存储项目编号
        remark: "",// 备注
        provinceId: null, // 省份Id
        cityId: null, // 城市Id
        areaId: null,
        provinceName: null, // 省份Id
        cityName: null, // 城市Id
        areaName: null,
        address: "" // 详细地址
    }

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
    protected showUserPopup: boolean = false;

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 地址
     * @private
     * @returns any
     */
    protected get address(): any
    {
        if(this.formData.provinceName)
        {
            return `${this.formData.provinceName}${this.formData.cityName}${this.formData.areaName}`
        }
        return null;
        
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getCellItem();
        this.selectUser =
        {
            id: this.userInfo.userId,
            memberType: 1,
            familyType: 1,
            memberName: this.userInfo.userName
        };

        this.formData.memberId = this.userInfo.userId;
        this.formData.memberServiceItemId = this.serviceItemId;
    }

    /**
     * 确认选择城市
     * @private
     * @returns void
     */
    private onAreaConfirm(data:any, indexs: Array<any>): void
    {
        if(data[0])
        {
            this.formData.provinceName = data[0].name;
            this.formData.provinceId = data[0].id;
        }
        if(data[1])
        {
             this.formData.cityName = data[1] && data[1].name;
             this.formData.cityId = data[1] && data[1].id;
        }
        if(data[2])
        {
            this.formData.areaName = data[2].name;
            this.formData.areaId = data[2].id;
        }
        
        this.showArea = false;
    }

    /**
     * 选择用户
     * @private
     * @returns void
     */
    protected onShowUserModal(): void
    {
        // 权益跳转过来的直接编辑当前用户否则选择用户
        if(this.serviceItemId)
        {
            this.$router.push({name: "family-operation",query: {userId: this.userInfo.userId}});
        }
        else
        {
            this.showUserPopup = true;
        }
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        if(data.id !== this.selectUser.id)
        {
            this.formData.memberId = data.id;
            this.selectUser = Object.assign({},data);
        }
    }

    /**
     * 确定日期
     * @private
     * @returns void
     */
    private onConfirmDate(): void
    {
        this.formData.reserveTime = dayjs(this.selectDate).format("YYYY/MM/DD");
        this.showDate = false;
    }

    /**
     * 获取详情
     * @private
     * @returns any
     */
    protected async getCellItem(): Promise<void>
    {
        try
        {
            let {content: result} = await CellService.instance.getCellItem();
            if(result)
            {
                this.detailInfo = result.data;
                this.formData.cellStorageItemId = result.data.id;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }


    /**
     * 验证表单信息
     * @private
     * @returns void
     */
    private verifyInfo(): boolean
    {
        if(!this.formData.provinceId)
        {
            Toast({
                duration: 1000,
                message: "请选择地址"
            });

            return false;
        }
        if(!this.formData.reserveTime)
        {
            Toast({
                duration: 1000,
                message: "请选择预约时间"
            });

            return false;
        }

        return true;
    }

    /**
     * 添加订单
     * @private
     * @returns any
     */
    protected async addCellOrder(): Promise<void>
    {
        try
        {
            if(this.verifyInfo())
            {
                let {content: result} = await CellService.instance.addCellOrder(this.formData);

                if(result.data)
                {
                    switch(result.data.orderStatus)
                    {
                        case 1:
                            {
                                this.onBridgeReady(result.data, result.data.orderId)
                                break;
                            }
                        case 2:
                            {
                                // 无需支付，跳转订单详情
                                this.$router.push({name: "cellStockpile-makeSuccss", query:{orderId: result.data.orderId,actionName: "cellStockpile-order-detail"}});
                                break;
                            }
                    }
                }
            }
            
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any, orderId: string): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.$router.push({name: "cellStockpile-makeSuccss", query:{orderId,actionName: "cellStockpile-order-detail"}});
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.$router.push({name: "cellStockpile-order-detail", query:{orderId}});
                }
                else
                {
                    self.$router.push({name: "cellStockpile-order-detail", query:{orderId}});
                    Toast.fail("支付失败");
                }
            }
        );
    }

}
