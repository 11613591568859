





































































































import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { Service } from "src/decorator";
import { CellService } from "src/services";
import dayjs from "dayjs";
declare let WeixinJSBridge: any 
declare let document: any

@Component
export default class OrderDetail extends Vue
{

     /**
     * 状态码
     * @private
     */
    private orderStatus: Array<any> =
    [
        {value: 0, name: "已取消", key: "Cancel", ms: "预约已取消"},
        {value: 1, name: "待付款", key: "ToPay"},
        {value: 2, name: "待确认", key: "Payed", ms: "等待医护人员确认"},
        {value: 3, name: "申请退款", key: "ApplyRefund"},
        {value: 4, name: "已退款", key: "Refunded"},
        {value: 5, name: "已完成", key: "Completed", ms: "预约成功"},
        {value: 6, name: "已关闭", key: "Closed"}
    ]


    /**
     * 订单id
     * @private
     */
    private time: number = 10 * 60 * 1000;
     /**
     * 订单id
     * @private
     */
    private orderId:number = null;

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 订单列表
     * @private
     * @returns Array<any>
     */
    private orderList: Array<any> = [];

    /**
     * 订单信息
     * @private
     */
    private orderJson: any = {
        itemModel: {},
        orderModel: {}
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }
    
    /**
     * 订单状态
     * @private
     * @returns number
     */
    private get reserveType(): number
    {
        return +this.$route.query.type;
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getOrderList(orderType: string | number = ""): Promise<void>
    {
        try
        {
            let { content: result } = await CellService.instance.h5OrderCellOrder(this.orderId);

            if(result.data)
            {
                this.orderJson.itemModel = result.data.itemModel || {};
                this.orderJson.orderModel = result.data.orderModel || {};
                let sysj = ((new Date()).valueOf() - dayjs(this.orderJson.orderModel.createdTime).valueOf());
                this.time = sysj > 10*60*1000 ? 0 : (10*60*1000 - sysj);
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.orderId = parseInt((this.$route.query as any).orderId);
        this.getOrderList();
    }

    /**
     * 跳转订单详情
     * @private
     * @returns void
     */
    private goOrderDetail(item: any): void
    {
        this.$router.push(
        {
            name: "orderDetail",
            params:
            {
                id: item.id
            }
        });
    }
    
    /**
     * 倒计时结束时触发
     * @protected
     */
    protected onOver(): void
    {
        this.getOrderList();
    }

    /**
     * 取消预约
     * @protected
     */
    protected cannelMake(): void
    {
        Dialog.confirm({
            title: '提示',
            message: '确定要取消预约吗？',
        })
        .then(async () => {
            try
            {
                let {content: res} = await CellService.instance.h5CancelCellOrder(this.orderId);
                Toast.success("取消成功！");
                this.getOrderList();
            }
            catch (err)
            {
                Toast(err)
            }
        })
        .catch(() => {
            // on cancel
        });
    }

    /**
     * 立即支付
     * @private
     * @returns void
     */
    private makePay(): void
    {
        Dialog.confirm({
            title: '提示',
            message: '确定要支付吗？',
        })
        .then(async () => {
            try
            {
                let {content: res} = await CellService.instance.h5ProceedPayCellOrder(this.orderId);
                let orderId = null;
                if (res.data)
                {
                    orderId = res.data.orderId
                }
                // 需要支付
                if (res.data.orderStatus == 1)
                {
                    this.onBridgeReady(res.data, res.data.orderId);
                }
                else // 不需要支付
                {
                    this.getOrderList();
                }
                
            }
            catch (err)
            {
                Toast(err)
            }
        })
        .catch(() => {
            // on cancel
        });
    }

    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any, orderId: string): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.getOrderList();
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.getOrderList();
                }
                else
                {
                    self.getOrderList();
                    Toast.fail("支付失败");
                }
            }
        );
    }

}
