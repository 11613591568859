











































































import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { CellService } from "src/services";
import dayjs from "dayjs";

@Component
export default class OrderList extends Vue
{

    /**
     * tab选中
     * @private
     * @returns number | string
     */
    private active: number | string = "";

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 订单列表
     * @private
     * @returns Array<any>
     */
    private orderList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }
    
    /**
     * 订单状态
     * @private
     * @returns number
     */
    private get reserveType(): number
    {
        return +this.$route.query.type;
    }

    /**
     * 获取订单列表 
     */
    protected async loadData(parameter: any): Promise<any> {
        try {
            let { content: res } = await CellService.instance.h5QueryCellOrder(
                Object.assign({orderStatus: this.active}, parameter)
            );
            return res.data;
        } catch (err) {
            Toast(err);
        }
    }

    /**
     * tab切换
     * @private
     * @returns void
     */
    private onTabChange(item: number): void
    {
        (this.$refs.myUPageList as any).onRefresh();
    }

    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onDetail(id: number): void
    {
        this.$router.push({name: "cellStockpile-order-detail", query: {orderId: id.toString()}});
    }

}
