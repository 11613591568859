






































































import { Component, Vue } from "vue-property-decorator";
import { CellService } from "src/services";
import { Toast } from "vant";

@Component
export default class ProductDetail extends Vue
{

    /**
     * tab选中
     * @private
     * @returns number | string
     */
    private active: number | string = "";

    /**
     * 详情
     * @private
     * @returns any
     */
    private detailInfo: any = {};

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 组件创建
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getCellItem();
    }

    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onMake(): void
    {
        this.$router.push({name: "cellStockpile-operation", query:{serviceItemId: this.serviceItemId}});
    }

    /**
     * 获取详情
     * @private
     * @returns any
     */
    protected async getCellItem(): Promise<void>
    {
        try
        {
            let {content: result} = await CellService.instance.getCellItem();
            if(result)
            {
                this.detailInfo = result.data;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

}
